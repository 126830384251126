
import { defineComponent, nextTick, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Rating from "../Rating.vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
const listData = ref();

const instructor = ref("")
export default defineComponent({
  name: "mandatory-detail",
  components: { Rating },
  data() {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
    };
  },
  setup() {
    /* eslint-disable */
    const route = useRoute();
    const router = useRouter();
    onMounted(() => {
      getDataList();
    });
    watch(
      () => route.path,
      () => {
        nextTick(() => {
          getDataList();
        });
      }
    );
    const getDataList = async () => {
      const getSyllabus = await axios.get(
        process.env.VUE_APP_API_URL + "/getSyllabus?id=" + route.params.sbId,
        {
          headers: {
            token: localStorage.getItem("id_token")
          },
        }
      );
      await axios
        .get(
          process.env.VUE_APP_API_URL + "/getCourse?id=" + route.params.cId +"&os=0",
          {
            headers: {
              token: localStorage.getItem("id_token")
            },
          }
        )
        .then((response) => {
          // console.log(response);
          listData.value = response.data.data.content[0];
          renderInstructor(listData.value.instructor)
          setCurrentPageBreadcrumbs(listData.value.title, [
            getSyllabus.data.data.content[0].name,
          ]);
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const register = () => {
      // console.log("register");
      if (localStorage.getItem("id_token")) {
        if (listData.value.register == false) {
          const formData = new FormData();
          formData.append("courseId", route.params.cId + "");
          // formData.append("coursePwd ", "true");
          axios
            .post(process.env.VUE_APP_API_URL + "/registerCourse", formData, {
              headers: { token: localStorage.getItem("id_token")},
            })
            .then((res) => {
              if (res.data.code == 200) {
                if (res.data.code == 200) {
                  Swal.fire({
                    title: "ลงทะเบียนสำเร็จ",
                    text: "รายการของคุณลงทะเบียนเรียบร้อยแล้ว",
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: "ตกลง!",
                    customClass: {
                      confirmButton: "btn fw-semobold btn-light-primary",
                    },
                  }).then(function(res) {
                    router.push({
                      name: "mandatory-learn",
                      params: {
                        sbId: route.params.sbId,
                        cId: route.params.cId,
                      },
                    });
                  });
                } else {
                  Swal.fire({
                    title: "แจ้งเตือนข้อผิดพลาด",
                    text: res.data.message,
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
                    customClass: {
                      confirmButton: "btn fw-semobold btn-light-danger",
                    },
                  });
                }
              } else {
                Swal.fire({
                  title: "แจ้งเตือนข้อผิดพลาด",
                  text: res.data.message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
                  customClass: {
                    confirmButton: "btn fw-semobold btn-light-danger",
                  },
                });
              }
            })
            .catch((error) => {
              // console.log(error);
            });
        } else {
          router.push({
            name: "mandatory-learn",
            params: { sbId: route.params.sbId, cId: route.params.cId },
          });
        }
      } else {
        router.push({ name: "sign-in" });
      }
    };
    const share = (social) => {
      const url = new URL(window.location.href);
      if (social == "facebook") {
        window.open(
          "http://facebook.com/sharer/sharer.php?u=" +
            encodeURIComponent(url.href),
          "",
          "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
        );
      } else if (social == "twitter") {
        window.open(
          "http://www.twitter.com/share?url=" + encodeURIComponent(url.href),
          "",
          "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
        );
      } else {
        window.open(
          "https://social-plugins.line.me/lineit/share?url=" +
            encodeURIComponent(url.href),
          "",
          "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
        );
      }
    };
    const renderInstructor = (uid) =>{
      console.log("renderInstructor");
      if (uid) {
        axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getProfile?id=" + uid ,
          {
            headers: {
              token: localStorage.getItem("id_token")
            },
          }
        )
        .then((response) => {
          instructor.value = response.data.data.firstName + " " +  response.data.data.lastName
        })
        .catch((error) => {
        });
      }else{
        instructor.value = "-"
      }
    
    }
    return {
      getDataList,
      listData,
      register,
      share,
      renderInstructor,
      instructor
    };
  },
  methods: {
    formatDate(date) {
      const event = new Date(date);
      return event.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    formatDateTime(date) {
      const event = new Date(date);
      return (
        event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }) + " น."
      );
    },
    rederRecommend(data) {
      // const dataMock = [
      //   "สำหรับประชาชนทั่วไป",
      //   "นักเรียนนักศึกษา",
      //   "คณะทำงานด้านเทคนิค",
      //   "เจ้าหน้าที่ปกครองส่วนท้องถิ่นวิศวกร",
      //   "ผู้พัฒนาโครงการ",
      // ];
      if (data.length > 0) {
        return data.join(", ");
      } else {
        return "-";
      }
    },
  },
});
